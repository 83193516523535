import React from "react"
import Img from "gatsby-image"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

const seoTitle = "Academic Calendar"

const AcademicCalendarPage = ({data}) => {
    let featuredImgFluid = data.markupql.edges[0].node.frontmatter.featuredImage.childImageSharp.fluid
    return <Layout>
        <SEO title={seoTitle}/>
        <div className="container catch-all">
            <div className="row article-banner">
                <div className="col">
                    <Img fluid={data.bannerql.childImageSharp.fluid} />
                </div>
            </div>
            <div className="row article-heading">
                <div className="col">
                    <h3><span className="label label-primary">{data.markupql.edges[0].node.frontmatter.heading}</span></h3>
                </div>
            </div>            
            <div className="row article-text">
                <div className="col">
                    <div
                        dangerouslySetInnerHTML={{ __html: data.markupql.edges[0].node.html }}
                    >
                    </div>
                    <hr></hr>
                    <Img fluid={featuredImgFluid} />
                </div>
            </div>
        </div>
    </Layout>
}
export default AcademicCalendarPage

export const query1 = graphql `
    query {
    bannerql: file(relativePath: {eq: "banners/academic-calendar-1.png"}) {
        childImageSharp {
            fluid(maxWidth: 1900) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    markupql: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/academic-calendar/i"}}) {
        edges {
          node {
            frontmatter {
              heading
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
          }
        }
    }
}
`